<template>
  <div
    class="amount-added d-flex flex-column animate__animated"
    :class="[
      Number(totalAmount) >= -15
        ? 'primary animate__fadeInUp'
        : 'red accent-4 animate__shakeX'
    ]"
  >
    <div class="d-flex flex-row flex-grow-1">
      <i class="material-icons-outlined white--text mr-2 ">{{
        Number(totalAmount) >= -15 ? icon : "warning"
      }}</i>
      <div
        class="font font-weight-medium  white--text pr-1"
        style="font-size: 14px !important;"
      >
        {{ label }}
      </div>
    </div>

    <span class="font pl-1 font-weight-medium font-size-md white--text pt-1">
      {{
        Number(totalAmount)
          | currencyFormat(
            currency && currency.length > 0 ? currency[0].code : "GHS"
          )
      }}</span
    >
  </div>
</template>
<script>
import { currencyFormat } from "../../../filters/currencyFormat";
export default {
  name: "AmountAccountedFor",
  props: {
    label: {
      type: String,
      default: ""
    },
    currency: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      default: "add_task"
    },
    totalAmount: {
      type: Number,
      default: 0
    }
  },
  filters: {
    currencyFormat
  }
};
</script>
